import styled from 'styled-components';
import dynamic from 'next/dynamic';

import { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useDesktop, useTablet } from 'src/utils/device';
import { WEB_NAME, WEB_TITLE } from 'src/constants/layout';
import { useRouter } from 'next/router';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import SearchBox from '../widgets/search-box';
import { SHOW_COMMEMORATE, APP_IMG } from 'src/constants';
import { SHOW_MEMBER } from 'src/services/member-auth/constants';
const NavDesktop = dynamic(import('src/components/layouts/nav-desktop'));
const NavMobile = dynamic(import('src/components/layouts/nav-mobile'));
const WidgetFollow = dynamic(import('src/components/widgets/widget-follow'));
const WidgetLogin = dynamic(import('src/services/member-auth/components/widget-login'));
const Header = ({ data, sessionSevId }: any) => {
  const router = useRouter();
  const [toggle, setToggle] = useState(false);
  const [expand, setExpand] = useState({ status: false, key: null });
  function handleToggle(_key: any, _index: any) {
    switch (_key) {
      case 'close':
        setToggle(false);
        setExpand({ key: null, status: false });
        break;
      case 'open':
        setToggle(!toggle);
        break;
      case 'menu':
        setToggle(true);
        setExpand({
          key: _index,
          status: expand?.key === _index ? !expand.status : true
        });
        break;
      default:
        setToggle(false);
        break;
    }
  }
  return (
    <>
      {useDesktop() && <>{toggle && <NavDesktop sessionSevId={sessionSevId} data={data} handleToggle={handleToggle} />}</>}
      <HeaderWrapper>
        <div className='nav-container container row'>
          <MenuArea className='col-3 w-100'>
            {useTablet() && (
              <>
                <NavMobile data={data} />
              </>
            )}
            {useDesktop() && (
              <>
                {(!toggle || !expand.status) && (
                  <FaBars
                    className='bars-desktop'
                    onClick={() => {
                      handleToggle('open', null);
                    }}
                  />
                )}
                <SearchBox />
                {SHOW_MEMBER === 'true' && <WidgetLogin sessionSevId={sessionSevId} />}
              </>
            )}
          </MenuArea>
          <div className='header-logo-menu col-6 w-100' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
            {router.asPath === '/' ? (
              <h1>
                <a
                  href='/'
                  title={WEB_TITLE}
                  //! DATALAYER
                  onClick={() =>
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK,
                      router: router?.pathname,
                      section: 'header',
                      data: {
                        title: 'logo',
                        heading: 'logo'
                      }
                    })
                  }
                >
                  <span>{WEB_TITLE}</span>
                  <img src={`${APP_IMG}/images/logo.png`} alt={WEB_NAME} width='100%' height='100%' />
                </a>
              </h1>
            ) : (
              <a
                href='/'
                //! DATALAYER
                onClick={() =>
                  sendDataLayerGlobal({
                    type: DATALAYER_TYPE.TRACK,
                    router: router?.pathname,
                    section: 'header',
                    data: {
                      title: 'logo',
                      heading: 'logo'
                    }
                  })
                }
              >
                <img src={`${APP_IMG}/images/logo.png`} alt={WEB_NAME} title={WEB_NAME} width='100%' height='100%' />
              </a>
            )}
          </div>
          <div className='col-3 w-100 col-3-w-100-member flex'>
            {useTablet() && (
              <>
                <SearchBox />
                {SHOW_MEMBER === 'true' && <WidgetLogin sessionSevId={sessionSevId} />}
              </>
            )}
            <div className='header-follow' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
              {' '}
              <WidgetFollow
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'follow',
                  position: 'on:header'
                }}
              />
            </div>
          </div>
        </div>
      </HeaderWrapper>
    </>
  );
};

const HeaderWrapper = styled.header`
  /* position: relative; */
  position: sticky;
  top: 0;
  margin: auto;
  border-bottom: 2px solid #84848424;
  z-index: 15;
  background-color: white;
  .col-3-w-100-member {
    @media (max-width: 768px) {
      display: flex;
    }
  }
  .nav-container {
    margin: auto;
    height: 100px;
    align-items: center;
    @media (max-width: 480px) {
      height: 70px;
    }
    .flex {
      display: flex;
      justify-content: flex-end;
    }
  }
  .header-logo-menu {
    text-align: center;
    h1 {
      margin: 0;
      overflow: hidden;
      position: relative;
      @media (max-width: 480px) {
        display: flex;
        align-items: center;
      }

      span {
        text-indent: 1000em;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        position: absolute;
        width: 100%;
      }
    }
    img {
      width: 300px;
      max-width: 100%;
    }
  }
  .header-follow {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 0 15px 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

const MenuArea = styled.div`
  display: flex;
  align-items: flex-end;
  /* padding: 0 0 15px 0; */
  .bars-desktop {
    cursor: pointer;
    font-size: 36px;
    color: #84848471;
  }
  svg {
    @media (max-width: 1024px) {
      font-size: 30px;
      // padding-bottom: 5px;
    }
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
`;

export default Header;
