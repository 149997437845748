import dynamic from 'next/dynamic';
import LazyLoad from 'react-lazyload';
import { APP_IMG } from 'src/constants';
import Images from 'next/image';

const ContentLoader = dynamic(import('src/components/content-loader'));

const Image = ({
  title,
  image,
  ignoreLazy,
  horizontal = false,
  exLazy = '',
  external = false,
  type
}: any) => {
  // console.log(image);
  let elem = <div className='resolution-image'>
    <img src={`${APP_IMG}/default.jpg`} alt={title || 'image'} title={title || 'image'} loading='lazy' width='100%' height='100%' />
  </div>;
  if (image) {
    let imgUrl = image;
    if (!external) {
      imgUrl = `${APP_IMG}${image}`;
    }
    // const imgUrl = image;
    switch (type) {
      case 'FEATURE_IMAGE': return (elem = <>
        <picture className='resolution-image'>
          {/* <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
          <source srcSet={`${imgUrl}?x-image-process=style/lg-webp`} media={'(max-width: 1440px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/lg`} media={'(max-width: 1440px)'} type='image/jpeg' />
          <img
            src={`${imgUrl}?x-image-process=style/lg`}
            alt={title}
            // title={title}
            loading='lazy'
            className={`${exLazy}`}
            width='100%'
            height='100%'
          />
        </picture>
      </>);
      case 'COVER_IMAGE': return (elem = <>
        <picture className='resolution-image'>
          <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/LG`} media={'(max-width: 767px)'} type='image/jpeg' />
          <source srcSet={`${imgUrl}?x-image-process=style/XL-webp`} type='image/webp' />
          <img
            src={`${imgUrl}?x-image-process=style/XL`}
            alt={title}
            // title={title}
            loading='lazy'
            className={`${exLazy}`}
            width='100%'
            height='100%'
          />
        </picture>
      </>);
      case 'CARD_LARGE': return (elem = <>
        <picture className='resolution-image'>
          {/* <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
          <source srcSet={`${imgUrl}?x-image-process=style/LG-webp`} type='image/webp' />
          <img
            src={`${imgUrl}?x-image-process=style/LG`}
            alt={title}
            // title={title}
            loading='lazy'
            className={`${exLazy}`}
            width='100%'
            height='100%'
          />
        </picture>
      </>);
      case 'CARD_MEDIUM': return (elem = <>
        <picture className='resolution-image'>
          {/* <source srcSet={`${imgUrl}?x-image-process=style/XS-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/XS`} media={'(max-width: 767px)'} type='image/jpeg' /> */}
          <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} type='image/webp' />
          <img
            src={`${imgUrl}?x-image-process=style/MD`}
            alt={title}
            // title={title}
            loading='lazy'
            className={`${exLazy}`}
            width='100%'
            height='100%'
          />
        </picture>
      </>);
      case 'CARD_SMALL': return (elem = <>
        <picture className='resolution-image'>
          <source srcSet={`${imgUrl}?x-image-process=style/SS-webp`} media={'(max-width: 767px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/SS`} media={'(max-width: 767px)'} type='image/jpeg' />
          <source srcSet={`${imgUrl}?x-image-process=style/XS-webp`} type='image/webp' />
          <img
            src={`${imgUrl}?x-image-process=style/XS`}
            alt={title}
            // title={title}
            loading='lazy'
            className={`${exLazy}`}
            width='100%'
            height='100%'
          />
        </picture>
      </>);
      case 'NEXT_IMAGE': return (elem = <>
        <picture className='resolution-image'>
            <Images
              src={`${imgUrl}`}
              // title={title}
              alt={title}
              priority
              layout='fill'
              quality={100} />
        </picture>
      </>);
      default: return (elem = <>
        <picture className='resolution-image'>
          {/* <source srcSet={`${imgUrl}?x-image-process=style/MD-webp`} media={'(max-width: 768px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/MD`} media={'(max-width: 768px)'} type='image/jpeg' /> */}
          <source srcSet={`${imgUrl}?x-image-process=style/lg-webp`} media={'(max-width: 1440px)'} type='image/webp' />
          <source srcSet={`${imgUrl}?x-image-process=style/lg`} media={'(max-width: 1440px)'} type='image/jpeg' />
          <img
            src={`${imgUrl}?x-image-process=style/lg`}
            alt={title}
            // title={title}
            loading='lazy'
            className={`${exLazy}`}
            width='100%'
            height='100%'
          />
        </picture>
      </>);
    }
  }

  if (ignoreLazy) return elem;
  return (
    <LazyLoad placeholder={<ContentLoader hasText={false} horizontal={horizontal} />}>{elem}</LazyLoad>
  );
};

export default Image;
