import dynamic from 'next/dynamic';
import styled from 'styled-components';
import { useState } from 'react';
import { FaSearch } from 'react-icons/fa';

const WidgetSearchFullPage = dynamic(import('src/components/widgets/widget-search-full-page'));

const SearchBox = ({ ...props }) => {
  const [searchToggle, setSearchToggle] = useState(false);

  function handleToggle() {
    setSearchToggle(!searchToggle);
  }

  return (
    <>
      <ButtonBase id='search-box' aria-label='right-align' onClick={() => handleToggle()} {...props}>
        <FaSearch />
      </ButtonBase>
      <WidgetSearchFullPage heading='Search Page' toggle={searchToggle} handle={handleToggle} />
    </>
  );
};

const ButtonBase = styled.button`
  display: block;
  outline: none;
  background: none;
  border-style: unset;
  cursor: pointer;
  margin: auto 0;
  @media (max-width: 768px) {
    margin: 0 0 0 auto;
  }
  svg {
    color: #84848471;
    font-size: 26px;
    margin-left: 10px;
    vertical-align: middle;
    @media (max-width: 1024px) {
      font-size: 30px !important;
      padding-top: 5px;
    }
  }
`;

export default SearchBox;
