import styled from 'styled-components';

const WidgetPlayBtn = () => {
  return (
    <PlayWrapper className='play-btn-video' />
  );
};

const PlayWrapper = styled.div`
      bottom: 5px;
      right: 5px;
      position: absolute;
      width: 35px;
      height: 35px;
      background: radial-gradient( rgba(237,26,59,0.9) 60%, rgba(255, 255, 255, 1) 62%);
      border-radius: 50%;
      display: block;
      box-shadow: 0px 0px 25px 3px rgba(237,26,59,0.9);
      z-index:3;
      @media (max-width: 480px) {
        width: 40px;
        height: 40px;
      }

        &::after {
        content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-40%) translateY(-50%);
          transform-origin: center center;
          width: 0;
          height: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 12px solid #fff;
          z-index: 100;
          transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          /* @media (max-width: 480px) {
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 18px solid #fff;
        } */
        }
        /* pulse wave */
        &:before {
          content: "";
          position: absolute;
          width: 150%;
          height: 150%;
          animation-delay: 0s;
          animation: pulsate1 2s;
          animation-direction: forwards;
          animation-iteration-count: infinite;
          animation-timing-function: steps;
          opacity: 1;
          border-radius: 50%;
          border: 5px solid rgba(255, 255, 255, .75);
          top: -25%;
          left: -25%;
          background: rgba(198, 16, 0, 0);
        }

      @-webkit-keyframes pulsate1 {
        0% {
          transform: scale(0.6);
          opacity: 1;
          box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
        }
        100% {
          transform: scale(1);
          opacity: 0;
          box-shadow: none;

        }
      }

      @keyframes pulsate1 {
        0% {
          transform: scale(0.6);
          opacity: 1;
          box-shadow: inset 0px 0px 25px 3px rgba(255, 255, 255, 0.75), 0px 0px 25px 10px rgba(255, 255, 255, 0.75);
        }
        100% {
          transform: scale(1);
          opacity: 0;
          box-shadow: none;

        }
      }
`;

export default WidgetPlayBtn;
